/*-----------------------------------------------------------------------------------

    Version: 1.0

-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
	
    01. Theme default CSS
        - Paginatoin Css
    02. Header Css
        - Header Bottom Css
        - Main Menu Css
        - Right Blok Box Css
        - Shopping Cart Css
        - Mobile Menu Css
    03. Categories Menu Css
    04. Hero Css
    05. Support Css
    06. Product Area Css
    07. Product Details Css
    08. Testimonial Css
    09. Banner Area Css
    10. Blog Area Css
    11. Footer Css
    12. Breadcrumb Style
    13. Widget Css   
    14. Deals Offer Css
    15. Brand Css
    16. About US css
    17. Contact Us Css
    18. Cart Page Css
    19. Checkout Page Css
    20. Error 404 Page Css
    21. Compare Page Css
    22. My Account Page Css
    23. Login And Register Css

-----------------------------------------------------------------------------------*/
@import '_variabls';
@import '_mixin';
@import '_common';
@import '_header';
@import '_categories-toggler';
@import '_hero';
@import '_support';
@import '_product';
@import '_product-details';
@import '_testimonial';
@import '_banner';
@import '_blog';
@import '_footer';
@import '_breadcrumb';
@import '_widget';
@import '_deals-offer';
@import '_brand';
@import '_about-us';
@import '_contact';
@import '_cart';
@import '_checkout';
@import '_error-404';
@import '_compare';
@import '_my-account';
@import '_login-register';